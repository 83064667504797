<template>
    <div class="edit">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">Edit Post</div>
        </div>
        <div class="main">
            
            <textarea-autosize
                placeholder="Edit post text content"
                ref="myTextarea"
                v-model="text"
                :min-height="30"
            />
        </div>
        <div class="foot">
            <div class="note">You can only edit your post once</div>

            <loader :height="'35px'" v-if="loading"/>
            <div class="btn" @click="save()" v-else>Save</div>
        </div>
    </div>
</template>

<script>
import back from '../../icons/back.vue';
import loader from '../../components/loading.vue';
export default {
    components: {
        back,
        loader
    },
    mounted() {
        this.post = JSON.parse(localStorage.getItem('edit'))
        this.text = this.post.text
    },
    activated() {
        this.post = JSON.parse(localStorage.getItem('edit'))
        this.text = this.post.text
    },
    data() {
        return {
            post: {},
            text: '',
            loading: false
        }
    },
    methods: {
        back() {
            if (this.loading) { return }
            this.$router.go(-1)
        },
        save() {
            
            let text = this.text.replace(/(\r\n|\r|\n){2,}/g, '$1\n');
            let trim_text = text.trim()

            if (trim_text == '') {
                this.empty(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('text', trim_text)
            form.append('post_id', this.post.id)

            this.$http.post('/post/edit', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.edited()

                    this.post.edited = true
                    this.post.edit_text = trim_text
                    this.post.edit_date = r.date

                    this.$root.$data.new_edit = {
                        'id': this.post.id,
                        'edit_text': trim_text,
                        'edit_date': r.date
                    }
                    
                    localStorage.setItem('post', JSON.stringify(this.post))

                    this.$router.go(-1)
                    
                }
                this.loading = false
            })
        }
    },
    
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Your post is empty'
        },
        edited: {
            type: 'success',
            title: 'Edited',
            message: ''
        }
    }
}
</script>

<style scoped>

    .edit {
        height: 100vh;
        width: 100%;
        background-color: white;
    }
    .head {
        display: grid;
        grid-template-columns: calc(50% - 40px) 50%;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
    }
    .title {
        font-weight: 500;
    }

    
    .main {
        height: calc(100vh - 144px);
        overflow: scroll;
    }
    textarea {
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        box-sizing: border-box;
        border: none;
        outline: none;
        font-size: 16px;
    }


    .foot {
        border-top: 1px solid #ddd;
        padding: 15px;
    }
    .note {
        font-size: 12px;
        font-weight: 500;
        color: gray;
        margin-bottom: 10px;
    }
    .btn {
        font-weight: 500;
        font-size: 14px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        padding: 8px;
        text-align: center;
    }
</style>